
import { HttpClient } from './http-client';
import { PreviewToken } from './PreviewToken';
import { UserContext } from './UserContext';
import { LoginIdentity } from './LoginIdentity';
import { GuestIdentity } from './GuestIdentity';
import { LtpaIdentity } from './LtpaIdentity';
import { SwitchContract } from './SwitchContract';
import { SwitchOrganization } from './SwitchOrganization';
import { Cart } from './Cart';
import { ShippingInfo } from './ShippingInfo';
import { PaymentInstruction } from './PaymentInstruction';
import { AssignedCoupon } from './AssignedCoupon';
import { AssignedPromotionCode } from './AssignedPromotionCode';
import { Category } from './Category';
import { CatalogEntry } from './CatalogEntry';
import { Categoryview } from './Categoryview';
import { Layout } from './Layout';
import { PageDesign } from './PageDesign';
import { WidgetDefinition } from './WidgetDefinition';
import { Page } from './Page';
import { Coupon } from './Coupon';
import { Promotion } from './Promotion';
import { AssociatedPromotion } from './AssociatedPromotion';
import { OrderExport } from './OrderExport';
import { Catalog } from './Catalog';
import { CatalogExport } from './CatalogExport';
import { CatalogDownload } from './CatalogDownload';
import { OrderDownload } from './OrderDownload';
import { Extendedlogger } from './Extendedlogger';
import { Spot } from './Spot';
import { Event } from './Event';
import { Segment } from './Segment';
import { UserBehavior } from './UserBehavior';
import { MarketplaceSeller } from './MarketplaceSeller';
import { Api } from './Api';
import { Store } from './Store';
import { TermCondition } from './TermCondition';
import { RequisitionList } from './RequisitionList';
import { Job } from './Job';
import { HealthCheckPing } from './HealthCheckPing';
import { Contract } from './Contract';
import { Activity } from './Activity';
import { FileUploadJob } from './FileUploadJob';
import { Order } from './Order';
import { ContentUrl } from './ContentUrl';
import { Feature } from './Feature';
import { Aggregate } from './Aggregate';
import { AccessControlForView } from './AccessControlForView';
import { IndexHandler } from './IndexHandler';
import { InventoryAvailability } from './InventoryAvailability';
import { Wishlist } from './Wishlist';
import { CurrencyFormat } from './CurrencyFormat';
import { Price } from './Price';
import { DisplayPrice } from './DisplayPrice';
import { DisplayTax } from './DisplayTax';
import { SearchDisplay } from './SearchDisplay';
import { Configuration } from './Configuration';
import { Flow } from './Flow';
import { IntegrationConfiguration } from './IntegrationConfiguration';
import { SubstitutionParameters } from './SubstitutionParameters';
import { TokenUsage } from './TokenUsage';
import { Token } from './Token';
import { UrlKeyword } from './UrlKeyword';
import { RedirectRule } from './RedirectRule';
import { StoreLocator } from './StoreLocator';
import { Country } from './Country';
import { Geonode } from './Geonode';
import { Subscription } from './Subscription';
import { Organization } from './Organization';
import { MemberGroup } from './MemberGroup';
import { Person } from './Person';
import { Contact } from './Contact';
import { CheckoutProfile } from './CheckoutProfile';
import { UserActivate } from './UserActivate';
import { ApprovalStatus } from './ApprovalStatus';
import { Workspace } from './Workspace';
import { TaskGroup } from './TaskGroup';
import { Task } from './Task';
const publicClient = new HttpClient({
	baseUrl: process.env.NODE_ENV === 'production' ? '/wcs/resources':'/api/resources',
	isPublic: true
});
const privateClient = new HttpClient({
	baseUrl: (process.env.USE_MOCK === 'true' ? 'http://localhost:' + process.env.MOCK_HOST_PORT : process.env.TRANSACTION_ORIGIN as string) + '/wcs/resources',
});

export const transactionsPreviewToken = (pub: boolean) => new PreviewToken(pub ? publicClient : privateClient);
export const transactionsUserContext = (pub: boolean) => new UserContext(pub ? publicClient : privateClient);
export const transactionsLoginIdentity = (pub: boolean) => new LoginIdentity(pub ? publicClient : privateClient);
export const transactionsGuestIdentity = (pub: boolean) => new GuestIdentity(pub ? publicClient : privateClient);
export const transactionsLtpaIdentity = (pub: boolean) => new LtpaIdentity(pub ? publicClient : privateClient);
export const transactionsSwitchContract = (pub: boolean) => new SwitchContract(pub ? publicClient : privateClient);
export const transactionsSwitchOrganization = (pub: boolean) => new SwitchOrganization(pub ? publicClient : privateClient);
export const transactionsCart = (pub: boolean) => new Cart(pub ? publicClient : privateClient);
export const transactionsShippingInfo = (pub: boolean) => new ShippingInfo(pub ? publicClient : privateClient);
export const transactionsPaymentInstruction = (pub: boolean) => new PaymentInstruction(pub ? publicClient : privateClient);
export const transactionsAssignedCoupon = (pub: boolean) => new AssignedCoupon(pub ? publicClient : privateClient);
export const transactionsAssignedPromotionCode = (pub: boolean) => new AssignedPromotionCode(pub ? publicClient : privateClient);
export const transactionsCategory = (pub: boolean) => new Category(pub ? publicClient : privateClient);
export const transactionsCatalogEntry = (pub: boolean) => new CatalogEntry(pub ? publicClient : privateClient);
export const transactionsCategoryview = (pub: boolean) => new Categoryview(pub ? publicClient : privateClient);
export const transactionsLayout = (pub: boolean) => new Layout(pub ? publicClient : privateClient);
export const transactionsPageDesign = (pub: boolean) => new PageDesign(pub ? publicClient : privateClient);
export const transactionsWidgetDefinition = (pub: boolean) => new WidgetDefinition(pub ? publicClient : privateClient);
export const transactionsPage = (pub: boolean) => new Page(pub ? publicClient : privateClient);
export const transactionsCoupon = (pub: boolean) => new Coupon(pub ? publicClient : privateClient);
export const transactionsPromotion = (pub: boolean) => new Promotion(pub ? publicClient : privateClient);
export const transactionsAssociatedPromotion = (pub: boolean) => new AssociatedPromotion(pub ? publicClient : privateClient);
export const transactionsOrderExport = (pub: boolean) => new OrderExport(pub ? publicClient : privateClient);
export const transactionsCatalog = (pub: boolean) => new Catalog(pub ? publicClient : privateClient);
export const transactionsCatalogExport = (pub: boolean) => new CatalogExport(pub ? publicClient : privateClient);
export const transactionsCatalogDownload = (pub: boolean) => new CatalogDownload(pub ? publicClient : privateClient);
export const transactionsOrderDownload = (pub: boolean) => new OrderDownload(pub ? publicClient : privateClient);
export const transactionsExtendedlogger = (pub: boolean) => new Extendedlogger(pub ? publicClient : privateClient);
export const transactionsSpot = (pub: boolean) => new Spot(pub ? publicClient : privateClient);
export const transactionsEvent = (pub: boolean) => new Event(pub ? publicClient : privateClient);
export const transactionsSegment = (pub: boolean) => new Segment(pub ? publicClient : privateClient);
export const transactionsUserBehavior = (pub: boolean) => new UserBehavior(pub ? publicClient : privateClient);
export const transactionsMarketplaceSeller = (pub: boolean) => new MarketplaceSeller(pub ? publicClient : privateClient);
export const transactionsApi = (pub: boolean) => new Api(pub ? publicClient : privateClient);
export const transactionsStore = (pub: boolean) => new Store(pub ? publicClient : privateClient);
export const transactionsTermCondition = (pub: boolean) => new TermCondition(pub ? publicClient : privateClient);
export const transactionsRequisitionList = (pub: boolean) => new RequisitionList(pub ? publicClient : privateClient);
export const transactionsJob = (pub: boolean) => new Job(pub ? publicClient : privateClient);
export const transactionsHealthCheckPing = (pub: boolean) => new HealthCheckPing(pub ? publicClient : privateClient);
export const transactionsContract = (pub: boolean) => new Contract(pub ? publicClient : privateClient);
export const transactionsActivity = (pub: boolean) => new Activity(pub ? publicClient : privateClient);
export const transactionsFileUploadJob = (pub: boolean) => new FileUploadJob(pub ? publicClient : privateClient);
export const transactionsOrder = (pub: boolean) => new Order(pub ? publicClient : privateClient);
export const transactionsContentUrl = (pub: boolean) => new ContentUrl(pub ? publicClient : privateClient);
export const transactionsFeature = (pub: boolean) => new Feature(pub ? publicClient : privateClient);
export const transactionsAggregate = (pub: boolean) => new Aggregate(pub ? publicClient : privateClient);
export const transactionsAccessControlForView = (pub: boolean) => new AccessControlForView(pub ? publicClient : privateClient);
export const transactionsIndexHandler = (pub: boolean) => new IndexHandler(pub ? publicClient : privateClient);
export const transactionsInventoryAvailability = (pub: boolean) => new InventoryAvailability(pub ? publicClient : privateClient);
export const transactionsWishlist = (pub: boolean) => new Wishlist(pub ? publicClient : privateClient);
export const transactionsCurrencyFormat = (pub: boolean) => new CurrencyFormat(pub ? publicClient : privateClient);
export const transactionsPrice = (pub: boolean) => new Price(pub ? publicClient : privateClient);
export const transactionsDisplayPrice = (pub: boolean) => new DisplayPrice(pub ? publicClient : privateClient);
export const transactionsDisplayTax = (pub: boolean) => new DisplayTax(pub ? publicClient : privateClient);
export const transactionsSearchDisplay = (pub: boolean) => new SearchDisplay(pub ? publicClient : privateClient);
export const transactionsConfiguration = (pub: boolean) => new Configuration(pub ? publicClient : privateClient);
export const transactionsFlow = (pub: boolean) => new Flow(pub ? publicClient : privateClient);
export const transactionsIntegrationConfiguration = (pub: boolean) => new IntegrationConfiguration(pub ? publicClient : privateClient);
export const transactionsSubstitutionParameters = (pub: boolean) => new SubstitutionParameters(pub ? publicClient : privateClient);
export const transactionsTokenUsage = (pub: boolean) => new TokenUsage(pub ? publicClient : privateClient);
export const transactionsToken = (pub: boolean) => new Token(pub ? publicClient : privateClient);
export const transactionsUrlKeyword = (pub: boolean) => new UrlKeyword(pub ? publicClient : privateClient);
export const transactionsRedirectRule = (pub: boolean) => new RedirectRule(pub ? publicClient : privateClient);
export const transactionsStoreLocator = (pub: boolean) => new StoreLocator(pub ? publicClient : privateClient);
export const transactionsCountry = (pub: boolean) => new Country(pub ? publicClient : privateClient);
export const transactionsGeonode = (pub: boolean) => new Geonode(pub ? publicClient : privateClient);
export const transactionsSubscription = (pub: boolean) => new Subscription(pub ? publicClient : privateClient);
export const transactionsOrganization = (pub: boolean) => new Organization(pub ? publicClient : privateClient);
export const transactionsMemberGroup = (pub: boolean) => new MemberGroup(pub ? publicClient : privateClient);
export const transactionsPerson = (pub: boolean) => new Person(pub ? publicClient : privateClient);
export const transactionsContact = (pub: boolean) => new Contact(pub ? publicClient : privateClient);
export const transactionsCheckoutProfile = (pub: boolean) => new CheckoutProfile(pub ? publicClient : privateClient);
export const transactionsUserActivate = (pub: boolean) => new UserActivate(pub ? publicClient : privateClient);
export const transactionsApprovalStatus = (pub: boolean) => new ApprovalStatus(pub ? publicClient : privateClient);
export const transactionsWorkspace = (pub: boolean) => new Workspace(pub ? publicClient : privateClient);
export const transactionsTaskGroup = (pub: boolean) => new TaskGroup(pub ? publicClient : privateClient);
export const transactionsTask = (pub: boolean) => new Task(pub ? publicClient : privateClient);
