'use client';
import { isEmpty } from 'lodash';
import Script from 'next/script';
import { useEffect, useState } from 'react';

/* client side only due to need to access the domain to determine the BV script path */

export const BVDomainScript = () => {
	const [domain, setDomain] = useState('');

	useEffect(() => {
		setDomain(document.domain);
	}, []);

	return !isEmpty(domain) ? (
		<Script
			src={`https://apps.bazaarvoice.com/deployments/galeton/main_site/${
				new RegExp('^(www|m|www-prodlive).galeton.com$').test(domain) ? 'production' : 'staging'
			}/en_US/bv.js`}
		></Script>
	) : null;
};
